import { segmentsPaths } from "./getSegmentPathById";

export function getSegmentIdByPath(pathname) {
  if (pathname.includes(segmentsPaths.ATID_BARI.path)) {
    return segmentsPaths.ATID_BARI.id;
  }
  if (pathname.includes(segmentsPaths.OVDIM_BARI.path)) {
    return segmentsPaths.OVDIM_BARI.id;
  }
  if (pathname.includes(segmentsPaths.CLALIT.path)) {
    return segmentsPaths.CLALIT.id;
  }
  if (pathname.includes(segmentsPaths.MEUHEDET_KIDS.path)) {
    return segmentsPaths.MEUHEDET_KIDS.id;
  }
  if (pathname.includes(segmentsPaths.YUVITAL_HEALTH_DEMO.path)) {
    return segmentsPaths.YUVITAL_HEALTH_DEMO.id;
  }
  if (pathname.includes(segmentsPaths.MEUHEDET.path)) {
    return segmentsPaths.MEUHEDET.id;
  }
  if (pathname.includes(segmentsPaths.SHEBA.path)) {
    return segmentsPaths.SHEBA.id;
  }
  if (pathname.includes(segmentsPaths.ELECTRA.path)) {
    return segmentsPaths.ELECTRA.id;
  }
  if (pathname.includes(segmentsPaths.ELECTRA_FM.path)) {
    return segmentsPaths.ELECTRA_FM.id;
  }
  if (pathname.includes(segmentsPaths.MAOF.path)) {
    return segmentsPaths.MAOF.id;
  }
  if (pathname.includes(segmentsPaths.NOF_HAGALIL.path)) {
    return segmentsPaths.NOF_HAGALIL.id;
  }
  if (pathname.includes(segmentsPaths.POLICE.path)) {
    return segmentsPaths.POLICE.id;
  }
  if (pathname.includes(segmentsPaths.FINANCE_MINISTRY.path)) {
    return segmentsPaths.FINANCE_MINISTRY.id;
  }
  if (pathname.includes(segmentsPaths.AMI_TECHNOLOGIES.path)) {
    return segmentsPaths.AMI_TECHNOLOGIES.id;
  }
  if (pathname.includes(segmentsPaths.SAVION.path)) {
    return segmentsPaths.SAVION.id;
  }
  if (pathname.includes(segmentsPaths.INTEL.path)) {
    return segmentsPaths.INTEL.id;
  }
  if (pathname.includes(segmentsPaths.YUVITAL.path)) {
    return segmentsPaths.YUVITAL.id;
  }
  if (pathname.includes(segmentsPaths.EL_ROBLE.path)) {
    return segmentsPaths.EL_ROBLE.id;
  }
  if (pathname.includes(segmentsPaths.LEUMIT.path)) {
    return segmentsPaths.LEUMIT.id;
  }
  if (pathname.includes(segmentsPaths.SHEBA_LONGEVITY.path)) {
    return segmentsPaths.SHEBA_LONGEVITY.id;
  }
  if (pathname.includes(segmentsPaths.WOW_JUNIOR_A.path)) {
    return segmentsPaths.WOW_JUNIOR_A.id;
  }
  if (pathname.includes(segmentsPaths.WOW_JUNIOR_B.path)) {
    return segmentsPaths.WOW_JUNIOR_B.id;
  }
  if (pathname.includes(segmentsPaths.WOW_JUNIOR_C.path)) {
    return segmentsPaths.WOW_JUNIOR_C.id;
  }
  return segmentsPaths.OVDIM_BARI.id;
}
